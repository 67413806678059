import { createApiCall, createMutation, useConfiguredQuery } from 'lib/queries'
import { useMutation, useQuery, useQueryClient } from 'react-query'

const queryKey = ['tracking-events']

// ----- MUTATIONS -------

export const useCreateTrackingEventMutation = createMutation({
  queryKey,
  method: 'POST',
  path: '/tracking-events/:eventName/:userNanoId',
})
