import useLocalStorageState from 'use-local-storage-state'
import { Button, ModalFooter, ModalSection, Textarea, TextLink } from '@kiwicom/orbit-components'
import Gap from 'lib/components/Gap'
import React, { useEffect, useState } from 'react'
import { useCreateMessageMutation } from 'src/queries/messages'
import Alert from 'src/components/Alert'
import { useMessagesHistory } from 'src/hooks/useMessagesHistory'
import Col from 'lib/components/Col'
import { useAuth } from 'lib/auth/useAuth'
import { useRouter } from 'next/router'
import * as gtag from 'src/gtag/events'
import BetterModal from './BetterModal'
import ModelField from './fields/ModelField'
import ModelFormik from './fields/ModelFormik'

const defaultMessage = `Dobrý den,
poptávám vás ohledně tvorby videa. Níže posílám úvodní detaily k vypracování nabídky.

TYP VIDEA
Popište, jaké video chcete natočit? Např. svatební, produktové, reklamní...


DÉLKA VIDEA


KDY MÁ BÝT HOTOVO


ROZPOČET


CO NEJVÍCE O PROMOVANÉ SLUŽBĚ, PRODUKTU, FIRMĚ


PRO KOHO JE VIDEO URČENÉ


CO JE CÍL VIDEA?


JAK ZAPADÁ VIDEO DO VAŠÍ MARKETINGOVÉ STRATEGIE


JAKÁ PODOBNÁ VIDEA SE VÁM LÍBÍ? A PROČ? 


Sem napište další doplňující informace - cokoli, co je pro váš projekt důležité. 
Pamatujte, že čím více detailů tvůrci poskytnete, tím přesnější nabídku dostanete. 
Kvalitní zadání šetří čas oběma stranám.
`

const ContactCreatorModal = ({ receiver, ...props }) => {
  const router = useRouter()
  const {
    query: { openContact, ...routerQuery },
    pathname,
  } = router

  const { addToReceiverHistory } = useMessagesHistory({ receiver: receiver._id })

  const createMessageMutation = useCreateMessageMutation({
    onSuccess: data => {
      // console.log('datadatadatadata', data)
      addToReceiverHistory(data)
      gtag.sendContact(receiver._id)
    },
  })
  const [sent, setIsSent] = useState(false)

  // console.log('createMessageMutation', createMessageMutation)
  const { mountedUser, user } = useAuth()

  const [savedMessage, setSavedMessage] = useLocalStorageState('message-form-message', {
    ssr: true,
    defaultValue: defaultMessage,
  })

  const [savedCredentials, setSavedCredentials] = useLocalStorageState('message-form-viewer', {
    ssr: true,
    defaultValue: { email: user?.email, name: user?.name, phone: user?.phone },
  })

  useEffect(() => {
    console.log('hook run')
    if (savedCredentials?._id !== user?._id && user?._id) {
      console.log('hook UPDATE run')
      setSavedCredentials({
        email: user?.email,
        name: user?.name,
        phone: user?.phone,
        _id: user?._id,
      })
    }
  }, [setSavedCredentials, savedCredentials?._id, user?._id, user?.email, user?.name, user?.phone])
  // console.log('mountedUser, user ', mountedUser, user)
  // console.log('savedCredentials', savedCredentials)

  return (
    <BetterModal
      defaultOpen={!!openContact}
      onClose={() => {
        router.push({
          pathname,
          query: { ...routerQuery },
        })
      }}
      title={`Oslovení: ${receiver.name}`}
      // confirmLeave
      {...props}
      body={({ closeModal }) =>
        sent ? (
          <ModalSection>
            <Alert.Success>
              Vaše zpráva byla úspěšně odeslána. Tvůrce vás bude kontaktovat.
            </Alert.Success>
            <Gap gap="24px" />
            <Button type="primary" onClick={closeModal}>
              Zavřít
            </Button>
          </ModalSection>
        ) : (
          <>
            <ModelFormik
              modelName="Message"
              validationSchemaKey="base"
              mutation={createMessageMutation}
              resetOnSuccess
              onSuccess={() => {
                setSavedMessage(defaultMessage)
                setIsSent(true)
              }}
              mergeInitialValues={{
                receiver: receiver._id,
                ...savedCredentials,
                message: savedMessage,
              }}
              // enableReinitialize
              validate={values => {
                // save user / viewer credentials
                setSavedCredentials({
                  ...savedCredentials,
                  email: values.email,
                  name: values.name,
                  phone: values.phone,
                })
                // save message, so we can see it on popup close
                setSavedMessage(values.message)
                return true
              }}
            >
              {({ isSubmitting, handleSubmit, errors, submitForm, values, ...rest }) => (
                <form onSubmit={handleSubmit}>
                  <ModalSection>
                    {receiver.moneyFrom && (
                      <>
                        <Alert type="warning" icon>
                          Tento tvůrce má nastaven minimální rozpočet na spolupráci:{' '}
                          {receiver.moneyFrom} Kč.
                        </Alert>
                        <Gap gap="24px" />
                      </>
                    )}

                    <ModelField
                      name="message"
                      rows="16"
                      helpClosable
                      width="100%"
                      help={(
                        <div>
                          Nevíte, co sem napsat? Zkuste doplnit předvyplněný text.
                        </div>
                      )}
                      />
                      {/* <TextLink>Přečtěte si doporučení</TextLink> */}

                    {/* <Gap gap="4px" />
                    <TextLink size="Normal">Jak nejlépe zadat poptávku?</TextLink> */}

                    <Gap gap="24px" />

                    <ModelField name="name" />
                    <Gap gap="24px" />
                    <ModelField name="email" />
                    <Gap gap="24px" />
                    <ModelField name="phone" />
                  </ModalSection>

                  <ModalFooter flex="1">
                    <Col alignItems="flex-start" width="100%">
                      <Col>
                        <Button type="primary" loading={isSubmitting} submit>
                          Odeslat
                        </Button>
                      </Col>

                      <Gap gap="16px" />
                      <Alert.ModelFormikError />
                    </Col>
                  </ModalFooter>
                </form>
              )}
            </ModelFormik>
          </>
        )}
    />
  )
}

export default ContactCreatorModal
