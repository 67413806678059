import { createApiCall, createMutation } from 'lib/queries'
import { useMutation, useQuery, useQueryClient } from 'react-query'

// TODO: move to libs after mature!

const queryKey = ['users', 'me']

export const useChangePasswordMutation = createMutation({
  // queryKey, omit queryKey not to invalidate user
  method: 'PUT',
  path: '/auth/change-password',
})

// TODO: move to libs after mature!

export const useResetPasswordInitMutation = createMutation({
  queryKey,
  method: 'POST',
  path: '/auth/init-reset-password',
})

export const useResetPasswordFinalMutation = createMutation({
  queryKey,
  method: 'POST',
  path: '/auth/finalize-reset-password',
})

export const useSignUpMutation = createMutation({
  queryKey,
  method: 'POST',
  path: '/auth/sign-up',
})

