import { up } from 'lib/styles'
import styled, { css } from 'styled-components'

const ContentBox = styled.div`
  ${({ mobilePadding = '24px 24px', padding = '32px 40px', maxWidth }) => css`
    background: #ffffff;
    box-shadow: 0px 0px 2px 0px rgb(37 42 49 / 16%), 0px 1px 4px 0px rgb(37 42 49 / 12%);
    border-radius: 3px;
    max-width: ${maxWidth};
    width: 100%;
    padding: ${mobilePadding || padding};
    ${up('mobile')} {
      padding: ${padding};
    }
  `}
`

export default ContentBox
