import { createApiCall, createMutation, useConfiguredQuery } from 'lib/queries'
import { useMutation, useQuery, useQueryClient } from 'react-query'

const queryKey = ['videos']

const fetchMyVideos = createApiCall({ path: 'my-videos' })
const fetchMyVideo = createApiCall({ path: 'my-videos/:_id' })

// get all my orders
export const useMyVideosQuery = config => useConfiguredQuery(queryKey, fetchMyVideos, config)

/// get mine by ID
export const useMyVideoQuery = (_id, config) =>
  useConfiguredQuery([queryKey, { params: { _id } }], fetchMyVideo, config)

// ----- MUTATIONS -------

export const useCreateVideoMutation = createMutation({
  queryKey,
  method: 'POST',
  path: '/my-videos',
})

export const useUpdateVideoMutation = createMutation({
  queryKey,
  method: 'PUT',
  path: '/my-videos/:_id',
})

export const useUpdateVideosOrderMutation = createMutation({
  queryKey,
  method: 'PUT',
  path: '/my-videos/order',
})

export const useDeleteVideoMutation = createMutation({
  queryKey,
  method: 'DELETE',
  path: '/my-videos/:_id',
})
