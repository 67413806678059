export const capitalizeFirstLetter = str => str.charAt(0).toUpperCase() + str.slice(1)

// sklonovani dle poctu
export const inflectNounByCount = (number, one, twoToFour, fiveAndMoreAndZero) => {
  if (number === 1) {
    return one
  }
  if (number > 1 && number < 5) {
    return twoToFour
  }
  if (number > 4 || number === 0) {
    return fiveAndMoreAndZero
  }
}
