// define events

import { event } from 'lib/gtag'

export const showVideo = videoId => {
  event({
    action: 'show_video',
    params: {
      video_id: videoId,
    },
  })
}

export const openContact = userId => {
  event({
    action: 'open_contact',
    params: {
      id: userId,
    },
  })
}

export const sendContact = userId => {
  event({
    action: 'send_contact',
    params: {
      id: userId,
    },
  })
}

export const signUp = () => {
  event({
    action: 'sign_up',
    params: {},
  })
}

export const activateAccount = () => {
  event({
    action: 'account_activated',
    params: {},
  })
}
