import {
  createApiCall,
  createMutation,
  getParamsFromQueryKey,
  useConfiguredInfiniteQuery,
  useConfiguredQuery,
} from 'lib/queries'
import { createInfinitePrefetch, createPrefetch } from 'lib/rq'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { fetchOneUser } from './users'

export const queryKey = ['videos']

export const fetchVideos = createApiCall({ path: 'videos' })
const fetchPaginatedVideos = createApiCall({ path: 'videos', enablePagination: true })
const fetchVideo = createApiCall({ path: 'videos/:nanoid' })

// get all or filtered orders
export const useVideosQuery = ({ queryParams } = {}, config) =>
  useConfiguredQuery([queryKey, { queryParams }], fetchVideos, config)

/// get one by nano id
export const useVideoQuery = (nanoid, config) =>
  // key has to be same as prefetch below
  useConfiguredQuery([queryKey, { params: { nanoid } }], fetchVideo, config)

export const prefetchVideos = mapQueryToApi => query => {
  const queryParams = mapQueryToApi(query)
  return createPrefetch([queryKey, { queryParams }], fetchVideos)
}

export const prefetchPaginatedVideos = mapQueryToApi => query => {
  const queryParams = mapQueryToApi(query)
  // console.log('query PREFETCH', query, queryParams)
  return createInfinitePrefetch(
    [queryKey, { queryParams, startPage: query.startPage || 1 }],
    fetchPaginatedVideos,
  )
}

export const prefetchVideo = mapQueryToApi => query => {
  const queryParams = mapQueryToApi(query)
  const { nanoid } = queryParams
  return createPrefetch([queryKey, { params: { nanoid } }], fetchVideo)
}

/// filter all videos with pagination
export const usePaginatedVideosQuery = ({ queryParams, startPage }, config) =>
  useConfiguredInfiniteQuery([queryKey, { queryParams, startPage }], fetchPaginatedVideos, config)

// prefetching user detail page data
// export const prefetchVideoAndUser = mapQueryToApi => query => {
//   const queryParams = mapQueryToApi(query)
//   // console.log('query prefetchUserAndVideos PREFETCH', query, queryParams)
//   const { nanoid } = queryParams
//   // this has to be same as fetch on FE
//   const videKey = [queryKey, { params: { nanoid } }]

//   return async queryClient => {
//     const prefetchVideoPromise = async () =>
//       queryClient.prefetchQuery(videKey, async ({ queryKey }) => {
//         const fetchedVideo = await fetchVideo(getParamsFromQueryKey(queryKey))
//         return fetchedVideo
//       })

//     return prefetchVideoPromise()
//   }
// }

export const homepageVideosQueryParams = {
  limit: 6,
  sort: '-createdAt',
}
