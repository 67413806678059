import useLocalStorageState from 'use-local-storage-state'

export const useMessagesHistory = ({ receiver }) => {
  const [messagesHistory, setMessagesHistory] = useLocalStorageState('messages-history', {
    ssr: true,
    defaultValue: {},
  })

  const receiverHistory = messagesHistory[receiver] || []

  const setReceiverHistory = history => {
    setMessagesHistory({ ...messagesHistory, [receiver]: history })
  }

  const addToReceiverHistory = message => {
    if (!receiver) {
      console.error('no receiver', receiver)
      return
    }

    setReceiverHistory([...receiverHistory, message])
  }

  return {
    messagesHistory,
    setMessagesHistory,
    receiverHistory,
    addToReceiverHistory,
    setReceiverHistory,
  }
}
