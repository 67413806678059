import {
  createApiCall,
  createMutation,
  getParamsFromQueryKey,
  useConfiguredInfiniteQuery,
  useConfiguredQuery,
} from 'lib/queries'
import { createPrefetch } from 'lib/rq'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { fetchVideos, queryKey as videosQueryKey } from './videos'

const queryKey = ['users']
const queryKeyMe = ['users', 'me']
const queryKeyMeProfileImage = ['users', 'me', 'profileImage']

const fetchOneUser = createApiCall({ path: 'users/:slug' })
const fetchUsers = createApiCall({ path: 'users' })
const fetchPaginatedUsers = createApiCall({ path: 'users', enablePagination: true })

const fetchMyStats = createApiCall({ path: 'users/me/stats' })

export const useMyStatsQuery = config =>
  useConfiguredQuery([queryKeyMe, 'stats'], fetchMyStats, config)

export const useUpdateProfileMutation = createMutation({
  queryKey: queryKeyMe,
  method: 'PUT',
  path: '/users/me',
})

export const useUpdateProfileImageMutation = createMutation({
  queryKey: queryKeyMeProfileImage,
  method: 'PUT',
  path: '/users/me-profile-image',
  invalidateQueries: queryKeyMe,
})

/// get limited user data by nanoid
// export const useUserQuery = (nanoid, config) =>
//   useConfiguredQuery([queryKey, { params: { nanoid } }], fetchOneUser, config)
export const useUserQuery = (slug, config) =>
  useConfiguredQuery([queryKey, { params: { slug } }], fetchOneUser, config)

export const useUsersQuery = ({ queryParams } = {}, config) =>
  useConfiguredQuery([queryKey, { queryParams }], fetchUsers, config)

/// filter all videos with pagination
export const usePaginatedUsersQuery = ({ queryParams, startPage }, config) =>
  useConfiguredInfiniteQuery([queryKey, { queryParams, startPage }], fetchPaginatedUsers, config)

export const prefetchUsers = mapQueryToApi => query => {
  const queryParams = mapQueryToApi(query)
  // console.log('query PREFETCH', query, queryParams)
  return createPrefetch([queryKey, { queryParams }], fetchUsers)
}

// prefetching user detail page data
export const prefetchUserAndVideos = mapQueryToApi => query => {
  const queryParams = mapQueryToApi(query)
  // console.log('query prefetchUserAndVideos PREFETCH', query, queryParams)

  const { slug } = queryParams

  const userKey = [queryKey, { params: { slug } }]

  return async queryClient => {
    let userId = null
    const prefetchUserPromise = async () =>
      queryClient.prefetchQuery(userKey, async ({ queryKey }) => {
        const fetchedUser = await fetchOneUser(getParamsFromQueryKey(queryKey))

        // console.log('fetchedUser', fetchedUser)

        userId = fetchedUser._id
        return fetchedUser
      })

    const prefetchUserVideosPromise = async userId => {
      const videosKey = [
        videosQueryKey,
        {
          queryParams: {
            filter: { author: userId },
            sort: 'sortOrder',
          },
        },
      ]

      return queryClient.prefetchQuery(videosKey, async ({ queryKey }) => {
        const videos = await fetchVideos(getParamsFromQueryKey(queryKey))

        // console.log('videos', videos)

        return videos
      })
    }

    // console.log('userId1', userId)
    await prefetchUserPromise()
    // console.log('userId2', userId)
    return prefetchUserVideosPromise(userId)
  }
}

export const homepageUsersQueryParams = {
  limit: 6,
  sort: '-createdAt',
}
