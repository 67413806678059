import styled from 'styled-components'
import { Page, PageContent } from 'src/components/Page'
import Gap from 'lib/components/Gap'
import { links } from 'src/links'
import { useAuth } from 'lib/auth/useAuth'
import Heading from '@kiwicom/orbit-components/lib/Heading'
import { useRouter } from 'next/router'
import BetterLink from 'src/components/BetterLink'
import { prefetchUserAndVideos, useUserQuery } from 'src/queries/users'
import PageLoader from 'src/components/PageLoader'
import UserDetail from 'src/components/UserDetail'
import dataFormatter, { formatCollection } from 'src/utils/dataFormatter'
import { Box, Button, Stack, Tag, Text, TextLink } from '@kiwicom/orbit-components'
import { linkKeys, skillsLabels, videoTypesLabels } from 'models/user'
import ContentBox from 'src/components/ContentBox'
import Col from 'lib/components/Col'
import Row from 'lib/components/Row'
import Icon from 'src/components/Icon'
import { capitalizeFirstLetter } from 'lib/strings'
import { useMyVideosQuery } from 'src/queries/myVideos'
import VideoCard, { VideosWrapper } from 'src/components/VideoCard'
import { prefetchVideos, useVideosQuery } from 'src/queries/videos'
import ProfileImage from 'src/components/ProfileImage'
import BetterModal from 'src/components/BetterModal'
import SEO from 'src/components/SEO'
import { createBasicServerSideProps } from 'lib/next'
import { Loader } from 'src/components/Loader'
import ContactCreatorModal from 'src/components/ContactCreatorModal'
import { useMessagesHistory } from 'src/hooks/useMessagesHistory'
import MessagesHistoryModal from 'src/components/MessagesHistoryModal'
import * as gtag from 'src/gtag/events'
import { useCreateTrackingEventMutation } from 'src/queries/trackingEvents'
import { useEffect } from 'react'

const TagWrapper = styled.div`
  padding-bottom: 8px;
`

export const WrappedTag = ({ children, href }) => {
  const result = (
    <TagWrapper>
      <Tag onClick={href ? () => {} : null}>{children}</Tag>
    </TagWrapper>
  )

  if (!href) {
    return result
  }

  // return <BetterLink.Pure href={{ pathname: links.CREATORS, query }}>{result}</BetterLink.Pure>
  return <BetterLink.Pure href={href}>{result}</BetterLink.Pure>
}

const CreatorPage = ({ ...props }) => {
  const {
    query: { slug, vkey },
  } = useRouter()

  const { mountedUser } = useAuth()

  // console.log('vkey', vkey)

  const { data, isLoading, isSuccess, isError, ...rest } = useUserQuery(slug, {
    enabled: !!slug,
  })

  const pageViewMutation = useCreateTrackingEventMutation({
    params: { userNanoId: data?.nanoid, eventName: 'pageView' },
    enabled: !!data?.nanoid,
  })
  const linkOpenedMutation = useCreateTrackingEventMutation({
    params: { userNanoId: data?.nanoid, eventName: 'linkOpened' },
    enabled: !!data?.nanoid,
  })

  const { receiverHistory } = useMessagesHistory({ receiver: data?._id })

  // console.log('isError', isError, data, isLoading, isSuccess)

  let { data: videos, isLoading: areVideosLoading } = useVideosQuery(
    {
      // if this is updated, change it in src/queries/user - prefetching
      queryParams: {
        filter: { author: data?._id },
        sort: 'sortOrder',
      },
    },
    {
      enabled: isSuccess,
    },
  )

  useEffect(() => {
    pageViewMutation.mutate()
  }, [])

  // console.log('areVideosLoading', areVideosLoading, isSuccess, videos)

  // console.log('rest', rest, data, isSuccess, isError)
  // todo - dořešit, co tedy kde bude vidět za data
  if (isLoading && !data) {
    return <PageLoader />
  }

  if (isError) {
    return (
      <PageLoader.Error title="Uživatel nebyl nalezen">
        Zkuste ho otevřít znovu. Pokud problém přetrvává, obraťte se na naší podporu. Děkujeme.
      </PageLoader.Error>
    )
  }

  // console.log('user data', data)

  const user = dataFormatter(data, 'user')
  videos = formatCollection(videos, 'video')

  const { isCompany } = user.original

  const weForm = (single, many) => isCompany ? many : single

  return (
    <>
      <SEO title={`${user.name} | Tvůrci`} />
      <Page bg="cloudLight">
        <PageContent>
          <Gap gap="24px" />
          <Row alignItems="flex-start" responsive mobileAlignItems="center">
            <ContentBox maxWidth="392px" padding="32px">
              <Col alignItems="center">
                <ProfileImage user={user} />
                <Gap gap="32px" />
                <Heading type="title2" as="h1" align="center">
                  {user.name}
                </Heading>
                <Gap gap="8px" />
                <Text size="normal">
                  <Icon.Location pointer={false} /> {user.region}
                </Text>
                <Gap gap="8px" />

                <Stack direction="row" wrap spacing="XXSmall" align="center" justify="center">
                  {linkKeys.map(key => {
                    const Component = Icon.Links[capitalizeFirstLetter(key)]

                    return (
                      user[key] && (
                        <a
                          href={user[key]}
                          key={key}
                          target="_blank"
                          rel="noreferrer nofollow"
                          onClick={() => {
                            linkOpenedMutation.mutate({ dimension: key })
                          }}
                          onAuxClick={() => {
                            // middle btn click
                            linkOpenedMutation.mutate({ dimension: key })
                          }}
                        >
                          <Component />
                        </a>
                      )
                    )
                  })}
                </Stack>
              </Col>
              <Gap gap="16px" />

              {mountedUser?._id !== user._id && (
                <>
                  <Col alignItems="stretch">
                    <ContactCreatorModal receiver={user}>
                      {({ openModal }) => (
                        <>
                          <Button
                            onClick={() => {
                              gtag.openContact(user._id)
                              openModal()
                            }}
                          >
                            Kontaktovat
                          </Button>
                        </>
                      )}
                    </ContactCreatorModal>

                    {receiverHistory?.length > 0 && (
                      <>
                        <Gap gap="16px" />
                        <Col alignItems="center">
                          <MessagesHistoryModal history={receiverHistory}>
                            {({ openModal }) => (
                              <>
                                <TextLink
                                  noUnderline
                                  size="small"
                                  onClick={() => {
                                    openModal()
                                  }}
                                >
                                  Tvůrce byl již kontaktován ({receiverHistory.length}x)
                                </TextLink>
                              </>
                            )}
                          </MessagesHistoryModal>
                        </Col>
                      </>
                    )}
                  </Col>
                  <Gap gap="32px" />
                </>
              )}

              <Col alignItems="flex-start">
                <Heading type="title2" as="h2">
                  {weForm('Točím', 'Točíme')} videa
                </Heading>
                <Gap gap="8px" />
                <Stack direction="row" spacing="XSmall" wrap>
                  {data.videoTypes.map(videoType => (
                    <WrappedTag
                      key={videoType}
                      href={{ pathname: links.CREATORS, query: { videa: videoType } }}
                    >
                      {videoTypesLabels[videoType]}
                    </WrappedTag>
                  ))}
                </Stack>

                <Gap gap="24px" />

                <Heading type="title2" as="h2">
                  {weForm('Mé', 'Naše')} dovednosti
                </Heading>
                <Gap gap="8px" />
                <Stack direction="row" spacing="XSmall" wrap>
                  {data.skills.map(skill => (
                    <WrappedTag
                      href={{ pathname: links.CREATORS, query: { dovednosti: skill } }}
                      key={skill}
                    >
                      {skillsLabels[skill]}
                    </WrappedTag>
                  ))}
                </Stack>

                <Gap gap="24px" />

                <Heading type="title2" as="h2">
                  Něco o {weForm('mně', 'nás')}
                </Heading>
                <Gap gap="8px" />
                <Text size="normal">{user.description}</Text>

                <Gap gap="24px" />

                <Heading type="title2" as="h2">
                  Minimální rozpočet na spolupráci
                </Heading>
                <Gap gap="8px" />
                <Text size="normal">{user.moneyFrom || 'Bez omezení'}</Text>

                <Gap gap="24px" />

                <Heading type="title2" as="h2">
                  {weForm('Domluvím', 'Domluvíme')} se
                </Heading>
                <Gap gap="8px" />
                <Stack direction="row" spacing="XSmall" wrap>
                  {user.languages.map(lang =>
                    <WrappedTag key={lang}>{lang}</WrappedTag>)}
                </Stack>

                <Gap gap="24px" />

                <Heading type="title2" as="h2">
                  Jednotlivec / tým
                </Heading>
                <Gap gap="8px" />
                <Text size="normal">{isCompany ? 'Jsme tým tvůrců' : 'Jsem jednotlivec'}</Text>
              </Col>
            </ContentBox>

            <Gap gap="40px" />

            <Col alignItems="flex-start" mobileAlignItems="center" grow="1">
              <Gap gap="40px" />
              <Heading type="title2" as="h2">
                Na čem jsem pracoval/a
              </Heading>
              <Gap gap="16px" mobileGap="32px" />
              {areVideosLoading && <Loader />}
              <VideosWrapper justify="flex-start">
                {videos?.map(v =>
                  <VideoCard video={v} key={v._id} user={user} withoutUserInfo />)}
              </VideosWrapper>
            </Col>
          </Row>

          <Gap gap="80px" />
        </PageContent>
      </Page>
      {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
    </>
  )
}

export default CreatorPage

CreatorPage.protected = false

export const getServerSideProps = createBasicServerSideProps({
  prefetchQueriesWithParams: [prefetchUserAndVideos(query => query)],
})
