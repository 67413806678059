import { createBasicServerSideProps, withSkipRevisit } from 'lib/next'
import { Fragment, useEffect, useState } from 'react'
import ReactPlayer from 'react-player/lazy'

import { Field, Form, Formik } from 'formik'

import Button from '@kiwicom/orbit-components/lib/Button'

import Heading from '@kiwicom/orbit-components/lib/Heading'

import { useAuth } from 'lib/auth/useAuth'
import { userChangePasswordSchema } from 'lib/auth/validate'
import { Page, PageContent } from 'src/components/Page'
import Gap from 'lib/components/Gap'
import {
  Box,
  Loading,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
  Portal,
  Separator,
  Stack,
  Tag,
  Text,
} from '@kiwicom/orbit-components'

import { links } from 'src/links'
import * as gtag from 'src/gtag/events'
import { useRouter } from 'next/router'
import BetterLink from 'src/components/BetterLink'
import { useChangePasswordMutation } from 'src/queries/auth'
import Alert from 'src/components/Alert'
import ModelFormik from 'src/components/fields/ModelFormik'
import TextInputField from 'src/components/fields/TextInputField'
import { useCreateVideoMutation, useUpdateVideoMutation } from 'src/queries/myVideos'
import ModelField from 'src/components/fields/ModelField'
import Row from 'lib/components/Row'
import styled, { css } from 'styled-components'
import { validationSchemas } from 'models/video'
import Col from 'lib/components/Col'
import Help from 'src/components/fields/Help'
import { WrappedTag } from 'src/pages/tvurci/[slug]'
import { format } from 'date-fns'
import dataFormatter from 'src/utils/dataFormatter'
import { useWindowResizeCallback } from 'lib/hooks/useWindowResizeCallback'
import { useIsMobile } from 'lib/hooks/useIsMobile'
import { theme } from 'src/styles'
import { up } from 'lib/styles'
import Link from 'next/link'
import { useCreateTrackingEventMutation } from 'src/queries/trackingEvents'
import ContentBox from './ContentBox'
import ProfileImage from './ProfileImage'
import Icon from './Icon'
import BetterButtonLink from './BetterButtonLink'

const VideoWrapper = styled.div`
  ${({ ratio = 16 / 9 }) => css`
    width: 100%;
    height: ${1280 / ratio}px;
    max-height: 80vh;
    background: black;
    position: relative;

    max-height: calc((100vw) / ${ratio});
    // modal BP
    ${up(theme.breakpoints.largeMobile)} {
      max-height: calc((100vw - 94px) / ${ratio});
    }
  `}
`

const LoadingWrapper = styled.div`
  ${() => css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `}
`

const ContentWrapper = styled.div`
  ${({ theme: { colors } }) => css`
    background: ${colors.cloudLight};
    border-radius: 0 0 12px 12px;
    padding: 40px 24px;
    ${up('mobile')} {
      padding: 50px 40px;
    }
    ${up(990)} {
      padding: 50px 70px;
    }
  `}
`

export function useVideoDetail({ video, user }) {
  const router = useRouter()
  const {
    query: { vkey },
  } = router
  const [detailShown, setDetailShown] = useState(vkey === video.nanoid)

  // autoclose popup when vkey is deleted from pathname
  useEffect(() => {
    if (!vkey && detailShown) {
      setDetailShown(false)
      console.log('closing popup')
    }
  }, [vkey])

  const showDetail = () => {
    setDetailShown(true)

    // console.log('router.query', router.query)
    router.push(
      {
        pathname: router.pathname,
        query: { ...router.query, vkey: video.nanoid },
      },
      undefined,
      { shallow: true },
    )
  }

  useEffect(() => {
    if (detailShown) {
      gtag.showVideo(video._id)
      console.log('event')
    }
  }, [detailShown, video._id])

  const onClose = () => {
    setDetailShown(false)

    // delete vkey on popup close
    const query = Object.keys(router.query).reduce((acc, key) => {
      if (key !== 'vkey') {
        acc[key] = router.query[key]
      }
      return acc
    }, {})

    router.push(
      {
        pathname: router.pathname,
        query,
      },
      undefined,
      // use shallow only if we are on the same page
      { shallow: true },
    )
  }

  const renderDetail = () =>
    detailShown && video && <VideoDetail video={video} user={user} onClose={onClose} />

  return {
    detailShown,
    setDetailShown,
    renderDetail,
    showDetail,
  }
}

const VideoDetail = ({ onClose, video, user }) => {
  // console.log('modal')
  const [isReady, setIsReady] = useState(false)
  const [isUrlCopied, setIsUrlCopied] = useState(false)

  if (user && !user.original) {
    user = dataFormatter(user, 'user')
  }

  // const modalCommonProps = {
  //   onClose: () => {
  //     onClose()
  //   },
  //   size: 'extraLarge',
  // }

  const isMobile = useIsMobile({ width: theme.breakpoints.largeMobile })
  // console.log('isMobile', isMobile)

  const authorPageLinkProps = {
    href: {
      pathname: links.CREATOR_PUBLIC_DETAIL,
      query: { slug: user.slug },
    },
    onClick: () => {
      onClose()
    },
  }

  const videoPlayedEventMutation = useCreateTrackingEventMutation({
    params: { userNanoId: user?.nanoid, eventName: 'videoOpened' },
    enabled: !!user?.nanoid,
  })

  useEffect(() => {
    videoPlayedEventMutation.mutate({ video: video?._id })
  }, [])

  // console.log('video', video)

  return (
    <Portal>
      <Modal
        // mobileHeader
        isMobileFullPage={isMobile}
        onClose={() => {
          onClose()
        }}
        size="extraLarge"
      >
        <ModalHeader title={video.name} />
        <Gap gap="32px" mobileGap="0" />
        <VideoWrapper ratio={video.ratio}>
          {!isReady && (
            <LoadingWrapper>
              <Loading />
            </LoadingWrapper>
          )}
          <ReactPlayer
            url={video.link}
            width="100%"
            height="100%"
            // light
            // muted
            controls
            onReady={player => {
              // console.log('ready')
              setIsReady(true)

              // window.player = player
              player.getInternalPlayer()?.playVideo?.()
              player.getInternalPlayer()?.play?.()
            }}
          />
        </VideoWrapper>

        <ContentWrapper>
          <Row mobileAlignItems="center" alignItems="flex-start" responsive>
            <Col width="100%">
              <Stack direction="column" spacing="XLarge">
                <Col>
                  <Heading type="title3">Popis videa</Heading>
                  <Text size="large">
                    {video.description
                      .split('\r\n')
                      .join('\n')
                      .split('\r')
                      .join('\n')
                      .split('\n')
                      .map((part, index) => (
                        <Fragment key={index}>
                          {index !== 0 && <br />}
                          {part}
                        </Fragment>
                      ))}
                  </Text>
                </Col>

                <Col>
                  <Heading type="title3">Předvedené dovednosti</Heading>

                  <Stack direction="row" spacing="XSmall" wrap>
                    {video.skills.map((skill, index) => (
                      <WrappedTag
                        key={skill}
                        href={{
                          pathname: links.VIDEOS,
                          query: { dovednosti: video.original.skills[index] },
                        }}
                      >
                        {skill}
                      </WrappedTag>
                    ))}
                  </Stack>
                </Col>

                <Col>
                  <Heading type="title3">Přibližný rozpočet (na práci tvůrce)</Heading>
                  <Text size="large">{video.budget ? `${video.budget} Kč` : 'Neuvedeno'}</Text>
                </Col>

                <Col>
                  <Heading type="title3">Typ videa</Heading>

                  <WrappedTag
                    href={{
                      pathname: links.VIDEOS,
                      query: { videa: video.original.videoType },
                    }}
                  >
                    {video.videoType}
                  </WrappedTag>
                </Col>

                <Col>
                  <Heading type="title3">Nahráno dne</Heading>
                  <Text size="large">{format(new Date(video.createdAt), 'dd.MM.yyyy')}</Text>
                </Col>
                <Col>
                  <Button
                    type="primarySubtle"
                    onClick={() => {
                      const text =
                        process.env.NEXT_PUBLIC_APP_URL +
                        links.VIDEO_DETAIL.replace('[nanoid]', video.nanoid)
                      navigator.clipboard.writeText(text).then(
                        function () {
                          console.log('Async: Copying to clipboard was successful!')
                          setIsUrlCopied(true)
                        },
                        function (err) {
                          console.error('Async: Could not copy text: ', err)
                        },
                      )
                    }}
                  >
                    {isUrlCopied ? 'Odkaz zkopírován do schránky' : 'Sdílet video'}
                  </Button>
                </Col>
              </Stack>
            </Col>

            {user && (
              <>
                <Gap gap="24px" />
                <ContentBox maxWidth="306px" padding="24px" width="100%">
                  <Col alignItems="center">
                    <BetterLink.Pure {...authorPageLinkProps}>
                      <ProfileImage user={user} />
                    </BetterLink.Pure>
                    <Gap gap="24px" />
                    <BetterLink.Pure {...authorPageLinkProps}>
                      <Heading type="title2" align="center">
                        {user.name}
                      </Heading>
                    </BetterLink.Pure>
                    <Gap gap="8px" />
                    <Text size="normal">
                      <Icon.Location pointer={false} /> {user.region}
                    </Text>
                  </Col>

                  <Gap gap="16px" />
                  <Separator />
                  <Gap gap="16px" />

                  <Col alignItems="flex-start">
                    <Heading type="title4">Tvořím videa</Heading>
                    <Gap gap="4px" />
                    <Stack direction="row" spacing="XSmall" wrap>
                      {user.videoTypes.map((videoType, index) => (
                        <WrappedTag
                          key={videoType}
                          href={{
                            pathname: links.CREATORS,
                            query: { videa: user.original?.videoTypes?.[index] },
                          }}
                        >
                          {videoType}
                        </WrappedTag>
                      ))}
                    </Stack>

                    <Gap gap="16px" />

                    <Heading type="title4">Mé dovednosti</Heading>
                    <Gap gap="4px" />
                    <Stack direction="row" spacing="XSmall" wrap>
                      {user.skills.map((skill, index) => (
                        <WrappedTag
                          key={skill}
                          href={{
                            pathname: links.CREATORS,
                            query: { dovednosti: user.original?.skills?.[index] },
                          }}
                        >
                          {skill}
                        </WrappedTag>
                      ))}
                    </Stack>

                    <Gap gap="24px" />

                    <Col alignItems="stretch" width="100%">
                      <BetterButtonLink type="primarySubtle" {...authorPageLinkProps}>
                        Zobrazit profil
                      </BetterButtonLink>
                      <Gap gap="16px" />
                      <BetterButtonLink
                        {...authorPageLinkProps}
                        href={{
                          ...authorPageLinkProps.href,
                          query: { ...authorPageLinkProps.href.query, openContact: 1 },
                        }}
                      >
                        Kontaktovat
                      </BetterButtonLink>
                    </Col>
                  </Col>
                </ContentBox>
              </>
            )}
          </Row>
        </ContentWrapper>
      </Modal>
    </Portal>
  )
}

export default VideoDetail
