import React from 'react'
import styled, { css } from 'styled-components'

// Orbit's help is one line limited.

const Wrapper = styled.span`
  ${({ theme: { colors }, isFilled }) => css`
    color: ${colors.textSecondary};
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    width: 100%;
    margin-top: 2px;
    top: 100%;
  `}
`

function Help({ children, ...props }) {
  // console.log('props', props)
  return <Wrapper {...props}>{children}</Wrapper>
}

export default Help
