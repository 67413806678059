import { useEffect, useRef, useState } from 'react'


export function useWindowResizeCallback({
  // these should be callbacks or static not to trigger rerender
  callbackInitial = () => {},
  callbackLate = () => {},
  debounce = 100,
}) {
  const timerRefInitial = useRef(null)

  useEffect(() => {
    function handleResize() {
      clearTimeout(timerRefInitial.current)
      timerRefInitial.current = setTimeout(() => {
        callbackInitial()
        requestAnimationFrame(callbackLate)
      }, debounce)
    }

    window.addEventListener('resize', handleResize)

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, [callbackInitial, callbackLate, debounce])

  return null
}

