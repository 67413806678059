import { Button, Tooltip } from '@kiwicom/orbit-components'
import { getImageSrc } from 'lib/next'
import { up } from 'lib/styles'
import Image from 'next/image'
import styled, { css } from 'styled-components'
import Icon from './Icon'

const placeholderPath = '/images/avatar-placeholder.svg'

const StyledImage = styled(Image).attrs(() => ({ quality: 90 }))`
  ${({ theme: { orbit }, size }) => css`
    /* border-radius: ${orbit.borderRadiusLarge}; */
    border-radius: 50%;
    /* width: ${size}px; */
    /* height: ${size}px; */
    img {
      max-width: 100%;
    }
  `}
`

const ButtonWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: 0.3s;
`

const IconWrapper = styled.div`
  border-radius: 200px;
  background: #e8edf1;
  padding: 4px;
  border: solid 1px #d3d3d3;
  color: #3c3c3c;
`

const IconPositioner = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
`

const Container = styled.div`
  position: relative;
`

const Wrapper = styled.div`
  ${({
  theme: { orbit },
  size,
  mobileSize,
  position = 'relative',
  pointer,
  alwaysShowButton,
}) => css`
    /* border-radius: ${orbit.borderRadiusLarge}; */
    position: ${position};
    border-radius: 50%;
    overflow: hidden;
    width: ${mobileSize || size};
    height: ${mobileSize || size};
    flex-shrink: 0;

    ${up('mobile')} {
      width: ${size};
      height: ${size};
    }

    ${pointer &&
    css`
      cursor: pointer;
    `}

    img {
      max-width: 100%;
      max-height: 100%;
    }

    &:hover {
      ${ButtonWrapper} {
        opacity: 1;
      }
    }

    ${alwaysShowButton &&
    css`
      ${ButtonWrapper} {
        opacity: 1;
      }
    `}
  `}
`

const ProfileImage = ({ onClick, user, src, mobileSize, size = '200px', pointer, withIcon }) => {
  // const imgSrc = user?.profileImage?.filePath
  //   ? `${process.env.NEXT_PUBLIC_APP_URL}${user.profileImage.filePath}`
  //   : placeholderPath

  const imgSrc = getImageSrc(user?.profileImage?.filePath, placeholderPath)
  // console.log('imgSrc', imgSrc, user?.profileImage?.blurData)
  const isCompany = user.original?.isCompany ?? user.isCompany

  // console.log('asdfasd', isCompany, user.original?.isCompany, user.isCompany)

  return (
    <Container>
      <Wrapper size={size} mobileSize={mobileSize} pointer onClick={onClick}>
        <StyledImage
          src={imgSrc}
          // size={size}
          //   width={size} todo - toto bude lepší pro optimalizaci asi, ale obrázek musí být čtverec
          //   height={size}
          // layout="fill"
          objectPosition="50% 50%"
          objectFit="cover"
          // loading="eager"
          placeholder={imgSrc === placeholderPath ? false : 'blur'}
          blurDataURL={user?.profileImage?.blurData}
          width={size}
          height={size}
        />
      </Wrapper>
      {withIcon && (
        <IconPositioner>
          <Tooltip placement="top" content={<div>{isCompany ? 'Tým' : 'Jednotlivec'}</div>}>
            <IconWrapper>{isCompany ? <Icon.People /> : <Icon.Person />}</IconWrapper>
          </Tooltip>
        </IconPositioner>
      )}
    </Container>
  )
}

const Clickable = ({ user, size = '200px', buttonText = 'Nahrát', onClick, alwaysShowButton }) => {
  const imgSrc = getImageSrc(user?.profileImage?.filePath, placeholderPath)

  // const imgSrc = user?.profileImage?.filePath
  //   ? `${process.env.NEXT_PUBLIC_APP_URL}${user.profileImage.filePath}`
  //   : placeholderPath
  // console.log('imgSrc', imgSrc, user?.profileImage?.blurData)

  return (
    <Wrapper size={size} alwaysShowButton={!user?.profileImage?.filePath && alwaysShowButton}>
      {/* <img src={user?.profileImage?.blurData} /> */}
      <StyledImage
        src={imgSrc}
        blurDataURL={user?.profileImage?.blurData}
        placeholder={imgSrc === placeholderPath ? false : 'blur'}
        // size={size}
        //   width={size} todo - toto bude lepší pro optimalizaci asi, ale obrázek musí být čtverec
        //   height={size}
        // layout="fill"
        objectPosition="50% 50%"
        objectFit="cover"
        width={size}
        height={size}
        priority
        loading="eager"
      />
      <ButtonWrapper>
        <Button onClick={onClick}>{buttonText}</Button>
      </ButtonWrapper>
    </Wrapper>
  )
}

ProfileImage.Clickable = Clickable

export default ProfileImage
