import { theme } from 'lib/styles'
import { useCallback, useState } from 'react'
import { useWindowResizeCallback } from './useWindowResizeCallback'

export function isMobile(width = theme?.breakpoints?.mobile) {
  if (typeof window === 'undefined') {
    return null
  }
  const mql = window.matchMedia(`(max-width: ${width}px)`)
  return mql.matches
}

export function useIsMobile({ width = theme?.breakpoints?.mobile, debounce } = {}) {
  const [isScreenMobile, setIsMobile] = useState(isMobile(width))

  const callbackInitial = useCallback(() => {
    setIsMobile(isMobile(width))
  }, [width])

  useWindowResizeCallback({
    callbackInitial,
    debounce,
  })

  return isScreenMobile
}
