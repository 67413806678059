import { Button, List, ListItem, ModalFooter, ModalSection, Text } from '@kiwicom/orbit-components'
import Gap from 'lib/components/Gap'
import React from 'react'
import styled from 'styled-components'
import { format } from 'date-fns'
import BetterModal from './BetterModal'

const Message = styled.div`
  background: #f2f2f2;
  border-radius: 4px;
  padding: 8px;
  margin: 4px 0 16px;
`

const MessagesHistoryModal = ({ title, history, ...props }) => (
  <BetterModal
    title="Historie oslovení tvůrce"
    size="small"
    {...props}
    body={({ closeModal }) => (
      <>
        <ModalSection>
          <Text>Tvůrce jste kontaktoval:</Text>
          <Gap gap="16px" />

          {history
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .map(item => (
              <div>
                <Text weight="bold">{format(new Date(item.createdAt), 'd. M. yyyy HH:mm')}</Text>
                <Message>
                  {item.message.split('\n').map(item => (
                    <Text>
                      {item}
                      <br />
                    </Text>
                  ))}
                </Message>
              </div>
            ))}

          <Gap gap="24px" />
        </ModalSection>

        <ModalFooter>
          <Button
            type="primary"
            onClick={() => {
              closeModal()
            }}
          >
            Zavřít
          </Button>
        </ModalFooter>
      </>
    )}
  />
)

export default MessagesHistoryModal
