import { Button, Dialog } from '@kiwicom/orbit-components'
import React, { useState } from 'react'

function DeleteDialog({ mutation, children, title, description = 'Akce nejde vzít zpět!' }) {
  const [isDialogShown, setIsDialogShown] = useState(false)

  return (
    <>
      {children({ openDialog: () => setIsDialogShown(true) })}

      {isDialogShown && (
        <Dialog
          description={description}
          title={title}
          primaryAction={(
            <Button
              type="critical"
              disabled={mutation.isLoading}
              loading={mutation.isLoading}
              onClick={async () => {
                try {
                  await mutation.mutateAsync()
                  setIsDialogShown(false)
                } catch (error) {
                  console.log('error', error)
                  alert('Akce se nepovedla, opakujte prosím')
                }
              }}
            >
              SMAZAT
            </Button>
          )}
          secondaryAction={(
            <Button
              disabled={mutation.isLoading}
              type="secondary"
              onClick={() => {
                setIsDialogShown(false)
              }}
            >
              Nemazat
            </Button>
          )}
          onClose={(...args) => {
            // console.log('closing', args)
            setIsDialogShown(false)
          }}
        />
      )}
    </>
  )
}

export default DeleteDialog
