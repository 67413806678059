import styled, { css } from 'styled-components'
import ReactPlayer from 'react-player'
import { Button, Loading, Stack, Text, TextLink, Tooltip } from '@kiwicom/orbit-components'
import Col from 'lib/components/Col'
import Gap from 'lib/components/Gap'
import Row from 'lib/components/Row'
import { useEffect, useRef, useState } from 'react'
import { links } from 'src/links'
import { useDeleteVideoMutation } from 'src/queries/myVideos'
import { useRouter } from 'next/router'
import { up } from 'lib/styles'
import dataFormatter from 'src/utils/dataFormatter'
import { useCreateTrackingEventMutation } from 'src/queries/trackingEvents'
import Icon from './Icon'
import BetterLink from './BetterLink'
import ProfileImage from './ProfileImage'
import DeleteDialog from './DeleteDialog'
import VideoDetail, { useVideoDetail } from './VideoDetail'

// TODO: react-player - loadovat lazy https://robertmarshall.dev/blog/lazy-load-youtube-video-iframe-show-on-scroll/

export const VideosWrapper = styled.div`
  ${({
  justify = 'flex-start',
  mobileJustify = 'center',
  margin = '0 0 0 -24px',
  mobileMargin = '0',
}) => css`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: ${mobileJustify || justify};
    align-self: stretch;
    position: relative;

    margin: ${mobileMargin};
    ${up('mobile')} {
      justify-content: ${justify};
      margin: ${margin};
    }
  `}
`

const DraggableControl = styled.div`
  height: 44px;
  border-radius: 3px;
  display: flex;
  flex: 0 0 auto;
  padding: 0 16px;
  align-items: center;
  background: #e8edf1;
  cursor: grab;
`

const ContentBox = styled.div`
  ${({
  maxWidth,
  disabled,
  maxHeight,
  margin = '0 0 24px 24px',
  mobileMargin = '0 0 32px 0',
}) => css`
    background: #ffffff;
    box-shadow: 0px 0px 2px 0px rgb(37 42 49 / 16%), 0px 1px 4px 0px rgb(37 42 49 / 12%);
    border-radius: 3px;
    /* height: 250px;
    width: 410px; */
    overflow: hidden;

    max-width: 410px;
    /* max-height: ${maxHeight}; */
    width: 100%;
    height: 100%;
    opacity: ${disabled ? 0.5 : 1};

    margin: ${mobileMargin || margin};
    ${up('mobile')} {
      margin: ${margin};
    }
  `}
`

const OverlayWrapper = styled.div`
  ${() => css`
    position: absolute;
    /* top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* pointer-events:none; */
  `}
`
const DetailButtonWrapper = styled.div`
  ${() => css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: 0.4s;
    display: flex;
    flex-direction: row;
  `}
`
const ThumbWrapper = styled.div`
  ${() => css`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    img {
      flex-shrink: 0;
      min-width: 100%;
      min-height: 100%;
      max-width: 100%;
    }
  `}
`
export const InfoTagWrapper = styled.div`
  ${({ top, left, bottom, right, show = true }) => css`
    position: absolute;
    top: ${top};
    left: ${left};
    bottom: ${bottom};
    right: ${right};
    transition: 0.4s;
    cursor: pointer;
    ${up('mobile')} {
      opacity: ${show ? 1 : 0};
    }
  `}
`

// const PlayerWrapper = styled.div`
//   ${({ ratio = 16 / 9 }) => css`
//     position: relative;
//     background: black;
//     max-width: 410px;
//     max-height: 230px;

//     width: 94vw;
//     height: 94vh;
//     height: calc((94vw) / ${ratio});

//     &:hover ${DetailButtonWrapper} {
//       opacity: 1;
//     }
//     &:hover ${InfoTagWrapper} {
//       opacity: 1;
//     }
//   `}
// `

const PlayerWrapperOuter = styled.div`
  ${({ ratio = 9 / 16 }) => css`
    position: relative;
    background: black;
    max-width: 410px;
    max-height: 230px;
    /* 
    width: 94vw;
    height: 94vh;
    height: calc((94vw) / ${ratio}); */

    position: relative;
    width: 100%;
    padding-bottom: ${ratio * 100}%;

    &:hover ${DetailButtonWrapper} {
      opacity: 1;
    }
    &:hover ${InfoTagWrapper} {
      opacity: 1;
    }
  `}
`
const PlayerWrapperInsider = styled.div`
  ${() => css`
    position: absolute;
    width: 100%;
    height: 100%;
  `}
`

const PlayerWrapper = ({ children }) => (
  <PlayerWrapperOuter>
    <PlayerWrapperInsider>{children}</PlayerWrapperInsider>
  </PlayerWrapperOuter>
)

const LoadingWrapper = styled.div`
  ${() => css`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  `}
`

const InfoTagContent = styled.div`
  ${() => css`
    background: rgba(0, 0, 0, 0.77);
    border-radius: 3px;
    padding: 2px 8px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: white;
    max-width: 220px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
  `}
`

export const InfoTag = ({ tooltip, children, ...props }) => (
  <InfoTagWrapper {...props}>
    <Tooltip placement="top" content={tooltip}>
      <InfoTagContent>{children}</InfoTagContent>
    </Tooltip>
  </InfoTagWrapper>
)

const VideoCard = ({
  video,
  editable,
  user,
  onEditClick,
  sortableProps: { ref, disabled, style, ...sortableContainerProps } = {},
  withoutUserInfo,
  ...props
}) => {
  const router = useRouter()
  const playerRef = useRef(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isEmbedded, setIsEmbedded] = useState(false)
  const videoStartedRef = useRef(false) // used not to send repeating play events
  // console.log('isPlaying', isPlaying, video)

  video = !video.original ? dataFormatter(video, 'video') : video

  const deleteMutation = useDeleteVideoMutation({
    params: { _id: video?._id },
    // enabled: !!activeConversation?._id,
  })

  const videoPlayedEventMutation = useCreateTrackingEventMutation({
    params: { userNanoId: user?.nanoid, eventName: 'videoPlayed' },
    enabled: !!user?.nanoid,
  })

  useEffect(() => {
    if (isPlaying) {
      // this is the first time video is played
      if (!videoStartedRef.current) {
        videoPlayedEventMutation.mutate({ video: video?._id })
      }
      videoStartedRef.current = true
    }
  }, [isPlaying])

  const { showDetail, renderDetail } = useVideoDetail({ video, user })

  let duration = null
  if (video.duration) {
    const minutes = Math.floor(video.duration / 60)
    const seconds = video.duration - 60 * minutes
    duration = `${minutes}:${seconds}`
  }

  return (
    <ContentBox maxWidth="410px" ref={ref} style={style} disabled={disabled}>
      {renderDetail()}
      <PlayerWrapper>
        {!isPlaying && (
          <ThumbWrapper
            onClick={() => {
              // playerRef.current.handleClickPreview()
              // setIsLoading(true)
              setIsPlaying(true)
            }}
          >
            <img src={video.thumbnail} alt="video thumbnail" loading="lazy" />
            {isLoading && (
              <LoadingWrapper>
                <Loading />
              </LoadingWrapper>
            )}
          </ThumbWrapper>
        )}

        {/* do not allow user to click on video */}
        {isPlaying && (
          <ThumbWrapper
            onClick={() => {
              setIsPlaying(false)
            }}
          />
        )}

        <InfoTag top="10px" left="10px" tooltip="Typ videa" show={!isPlaying}>
          {video.videoType}
        </InfoTag>
        {video.budget && (
          <InfoTag
            top="10px"
            right="10px"
            tooltip="Hrubý rozpočet za práci tvůrce"
            show={!isPlaying}
          >
            {video.budget} Kč
          </InfoTag>
        )}
        <InfoTag bottom="10px" left="10px" tooltip="Předvedené dovednosti" show={!isPlaying}>
          {video.skills.join(', ')}
        </InfoTag>
        {duration && (
          <InfoTag bottom="10px" right="10px" tooltip="Délka videa" show={!isPlaying}>
            {duration}
          </InfoTag>
        )}

        {editable && (
          <OverlayWrapper>
            <Row>
              <Button onClick={onEditClick}>Upravit</Button>
              <Gap gap="8px" />
              <DeleteDialog mutation={deleteMutation} title="Opravdu chcete smazat video?">
                {({ openDialog }) => (
                  <Button type="critical" onClick={openDialog}>
                    Smazat
                  </Button>
                )}
              </DeleteDialog>
              <Gap gap="8px" />
              <Button
                type="secondary"
                onClick={() => {
                  showDetail()
                }}
              >
                Detail
              </Button>
              <Gap gap="8px" />

              <DraggableControl {...sortableContainerProps} type="secondary">
                <Icon.Move />
              </DraggableControl>
            </Row>
          </OverlayWrapper>
        )}

        {!editable && (
          <>
            <DetailButtonWrapper>
              {!isPlaying && (
                <>
                  <Button
                    type="primarySubtle"
                    iconLeft={<Icon.Play />}
                    onClick={() => {
                      setIsEmbedded(true)
                      setIsPlaying(true)
                    }}
                  >
                    Přehrát
                  </Button>
                  <Gap gap="8px" />
                </>
              )}
              {isPlaying && (
                <>
                  <Button
                    type="criticalSubtle"
                    iconLeft={<Icon.Pause />}
                    onClick={() => {
                      setIsPlaying(false)
                    }}
                  >
                    Pauza
                  </Button>
                  <Gap gap="8px" />
                </>
              )}
              <Button
                type="secondary"
                onClick={() => {
                  setIsLoading(false)
                  setIsPlaying(false)
                  showDetail()
                }}
              >
                Detail
              </Button>
            </DetailButtonWrapper>
          </>
        )}
        {isEmbedded && (
          <ReactPlayer
            playing={isPlaying}
            ref={playerRef}
            url={video.link}
            width="410px"
            height="230px"
            width="100%"
            height="100%"
            // light --- TODO:
            controls={false}
            playIcon={<span />}
            muted
            onReady={player => {
              // console.log('ready')
              // window.player = player
              // player.getInternalPlayer()?.playVideo?.()
              // player.getInternalPlayer()?.play?.()
              setIsLoading(false)
            }}
            onPlay={player => {
              setIsPlaying(true)
              setIsLoading(false)
            }}
            onPause={() => {
              setIsPlaying(false)
              // playerRef.current?.showPreview?.()
            }}
            onEnded={() => {
              setIsPlaying(false)
            }}
            // fallback={<div>https://vimeo.com/76130887</div>}
          />
        )}
      </PlayerWrapper>
      <Col>
        <Gap gap="16px" />
        <Row alignItems="center">
          <Gap gap="16px" />
          {!editable && user && !withoutUserInfo && (
            <>
              <ProfileImage
                user={user}
                size="72px"
                onClick={() => {
                  router.push({
                    pathname: links.CREATOR_PUBLIC_DETAIL,
                    query: { slug: user.slug },
                  })
                }}
              />
              <Gap gap="12px" />
            </>
          )}
          <Col>
            <TextLink
              weight="bold"
              size="large"
              noUnderline
              type="secondary"
              onClick={() => {
                showDetail()
              }}
            >
              {video.name}
            </TextLink>
            {!editable && user && !withoutUserInfo && (
              <>
                <Gap gap="4px" />
                <BetterLink
                  size="small"
                  type="secondary"
                  noUnderline
                  href={{ pathname: links.CREATOR_PUBLIC_DETAIL, query: { slug: user.slug } }}
                >
                  {user.name}
                </BetterLink>
              </>
            )}
          </Col>
          <Gap gap="16px" />
        </Row>
        <Gap gap="16px" />
      </Col>
    </ContentBox>
  )
}
export default VideoCard
